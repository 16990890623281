import { useState, useEffect } from 'react';
import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { Link, useHref } from 'react-router-dom';
import AuthUser from './AuthUser';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Toast = Swal.mixin({
    position: 'top-end',
    timer: 2000,
    showConfirmButton: false,
    toast: true,
})

const Istanza = () => {

    const http = AuthUser().http;
    const [dati, setdati] = useState(null);
    const [allegati, setallegati] = useState(null);
    const [updatepage, setupdatepage] = useState(true);
    const user = AuthUser().user;
    const [prossimo, setprossimo] = useState(null);
    const [note, setnote] = useState('');
    const [esito, setesito] = useState('');
    const [precedente, setprecedente] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    const salvaesito = () => {

        http.post('/salvaesito',
            { identificativo: location.state.identificativo, id: location.state.id, esito: esito, note: note }).then(response => {
                // @ts-ignore 

                if (response.data.success) {
                    Toast.fire({
                        title: response.data.text,
                        icon: 'success'
                    });
                } else {
                    Toast.fire({
                        title: response.data.text,
                        icon: 'error'
                    });
                }
            });
    }

    useEffect(() => {
        setnote('');
        //   setdati(location.state.json);
        // console.log(location.state.identificativo);
        setallegati(location.state.json.allegati[0]);
        //  delete location.state.json.allegati;
        // delete location.state.json.figli;
        setdati(location.state.json);

        console.log(location.state);

        let elementnote = document.getElementById('note');

        if (elementnote) {
            // @ts-ignore
            elementnote.value = location.state.note;
            setnote(location.state.nota);
        }


        let element = document.getElementById('Esito');

        if (element) {
            // @ts-ignore
            if (element.value) {
                // @ts-ignore
                element.value = location.state.esito;
            } else {
                // @ts-ignore
                element.value = 'sel';
            }
            setesito(location.state.esito);
        }

        http.post('/prenext',
            { identificativo: location.state.identificativo, id: location.state.id, comune: user.comune }).then(response => {
                // @ts-ignore 
                //    console.log(response.data);
                if (response.data.length === 2) {
                    setprossimo(response.data[1]);
                    setprecedente(response.data[0]);
                } else {
                    if (response.data[0].pos == 'pre') {
                        setprecedente(response.data[0]);
                        setprossimo(null);
                    } else {
                        setprossimo(response.data[0]);
                        setprecedente(null);
                    }
                }
            });

    }, [location.state]);

    return (dati && <div> <a className="elementor-button-link elementor-button elementor-size-sm yellow" onClick={() => navigate('/istanze', { state: { identificativo: location.state.identificativo } })} role="button">
        Indietro</a>
        <h3>Istanza</h3>
        <p> Data Invio {location.state.datainvio}  id Domanda : {location.state.id}

            <a href={'https://api.comune.rende.cs.it/storage/app/ricevute/' + location.state.id + '.pdf'} target='_blank'> Ricevuta PDF</a>
<br/>
            {location.state.note>0?<a href={'https://servizisociali.comune.rende.cs.it/istanzasingola/' + location.state.note} target='_blank'> Prima Istanza Domanda con Allegati</a> :''}

        </p>

        <div className="card-body">
            <div className="container">
                <div className="row row-cols-4" >
                    {precedente && <a target='_blank' className="elementor-button-link elementor-button elementor-size-sm yellow" onClick={() =>
                        navigate('/istanza', { state: { identificativo: precedente.identificativo, datainvio: precedente.created_at, json: JSON.parse(precedente.istanzajson), id: precedente.id, note: precedente.note, esito: precedente.esito } })
                    } role="button">
                        precedente</a>}

                    {prossimo && <a style={{ float: 'right' }} target='_blank' className="elementor-button-link elementor-button elementor-size-sm yellow" onClick={() => navigate('/istanza', { state: { identificativo: prossimo.identificativo, datainvio: prossimo.created_at, json: JSON.parse(prossimo.istanzajson), id: prossimo.id, note: prossimo.note, esito: prossimo.esito } })} role="button">
                        prossima</a>}
                </div>
                <div className="row row-cols-4" >
                    {
                        Object.keys(dati).map((key, i) => (
                            <>{
                                !Array.isArray(dati[key]) &&
                                <div key={i} className="col" style={{ textTransform: 'uppercase', wordWrap: 'break-word', flex: '0 0 24%', maxWidth: '24%', border: 'solid 1px', margin: 4, padding: 5 }}> <strong> {key} :</strong>
                                    {dati[key] == true ? 'SI - '+dati[key] : dati[key] == false ? 'NO - '+dati[key] : dati[key]}  </div>

                            }
                                {
                                    Array.isArray(dati[key]) &&
                                    Object.keys(dati[key]).map((k, s) => (
                                        <div className="col" style={{ textTransform: 'uppercase', flex: '0 0 100%', maxWidth: '100%', border: 'solid' }}>
                                            <div className="row row-cols-4" style={{ textTransform: 'uppercase' }}><div className="col"> {key} {s + 1}</div> {
                                                Object.keys(dati[key][k]).map((d, v) => (
                                                    <div className="col" style={{ flex: '0 0 24%', wordWrap: 'break-word', maxWidth: '24%', border: 'solid 1px', margin: 4, padding: 5 }}>
                                                        <strong> {d}  :</strong> 
                                                        {
                                !Array.isArray(dati[key][k][d]) &&
                                dati[key][k][d]

                            }                           {
                                Array.isArray(dati[key][k][d]) &&
                                dati[key][k][d].length

                            }
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    )
                                    )
                                }
                            </>
                        )
                        )
                    }

                </div>
            </div>
        </div>

        <form action="salvacug.php" method="POST">
            <div className="row">
                <div className="col-sm" style={{ border: 'solid 1px' }}>
                    <h2>Allegati:</h2>
                    <div className='row'>
                        <div className='col-sm'>
                            <h4>File</h4>
                        </div>

                        <div className='col-sm'>
                            <h4>azioni</h4>
                        </div>
                    </div>
                    {
                        Object.keys(allegati).map((key, i) => (
                            <p key={i}>

                                <div className='row' style={{ borderBottom: 'solid 1px' }}>
                                    <div className='col-sm'>
                                        <a href={'https://api.comune.rende.cs.it/istanze/public/' + location.state.identificativo + '/' + allegati[key]} target={'_blank'} > {key}  </a>
                                    </div>
                                    <div className='col-sm'>
                                        <label onClick={() => {

                                            document.getElementById(`modd.${i}`).style.display = 'none';
                                            document.getElementById(`file.${i}`).style.display = 'inline';

                                        }} id={`modd.${i}`}  > modifica  </label>
                                        <span style={{ marginLeft: 60 }}>
                                            <input type="file" id={`file.${i}`} className="is-invalid" data-focus-mouse="false" aria-invalid="true" aria-describedby="upload-error-6"
                                                onChange={e => { }}
                                                style={{ display: 'none' }}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </p>

                        ))
                    }

                </div><div className="col-sm" style={{ border: 'solid 1px' }}>
                    <h2>Esito Istanza:</h2>
                    <div>
                        <select id='Esito' name='Esito' defaultValue={esito} onChange={event => setesito(event.target.value)}>
                            <option value='sel'>Seleziona ...</option>
                            <option value='accettata'>Accettata</option>
                            <option value='rifiutata'>Rifiutata</option>
                            <option value='integrare'>Da integrare</option>
                        </select>
                        <br />
                        <br />
                        <div >
                            <label htmlFor="note" >Note</label>
                            <textarea id="note" rows={3} data-focus-mouse="false" onChange={event => setnote(event.target.value)} defaultValue={note} />
                        </div>

                        <br />
                        <br />

                        <button name="_Salva" id="SalvaScheda" value="true" type="button" className="btn btn-primary btn-md pull-left" style={{ width: 150 }}
                            onClick={() => {

                                salvaesito();
                            }}
                        >Salva</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    )
}
export default Istanza;